type MenuRoute = {
  [key: string]: {
    name: string
    path: string
  }
}

const menuRoutes: MenuRoute = {
  initial: { name: 'init', path: '/initial' },
  product: { name: 'product', path: '/product' },
  file: { name: 'file', path: '/files' },
  client: { name: 'client', path: '/participants' },
  app: { name: 'integration', path: '/store/integrations' },
  account: { name: 'account', path: '/account' },
  dataUser: { name: 'dataUser', path: '/company-settings/geral' },
  certificate: { name: 'certificate', path: '/company-settings/certificate' },
  user: { name: 'user', path: '/company-settings/Users' },
  payment: { name: 'payment', path: '/company-settings/charge' },
  service: { name: 'service', path: '/company-settings/plan' },
}

export default menuRoutes
