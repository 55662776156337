import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { MagnifyingGlass, UserCircleGear } from 'phosphor-react'
import { FormEvent, useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as UserAdd } from 'src/assets/icons/user_add.svg'
import { ReactComponent as NotFindImage } from 'src/assets/imgs/not-find.svg'
import { ProfileTag } from 'src/components/ProfileTag'
import { User, useUsers } from './hooks'
import { useSession } from 'src/contexts/sessions'

export const Users = () => {
  const navigate = useNavigate()
  const [radFilter, setRadFilter] = useState('0')
  const [textFilter, setTextFilter] = useState('')

  const { session } = useSession()
  const sessionBtnAccess = session.btnsMenu
  const { users, isFetching } = useUsers()

  const filteredUsers = useCallback(() => {
    let res = null
    if (users) {
      res = users?.filter((user) =>
        user.name.toLocaleLowerCase().includes(textFilter),
      )

      if (radFilter === '0') {
        res = res?.filter((user) => user.status)
      }
      if (radFilter === '1') {
        res = res?.filter((user) => !user.status)
      }

      return res
    }
    return []
  }, [radFilter, textFilter, users])

  const handleSelectUserEditing = (user: User) => {
    localStorage.setItem('ws@user', JSON.stringify(user))
    navigate('../users/editing')
  }

  const checkAcessbtn = (nameMenu: string) => {
    return !!sessionBtnAccess.find(
      (item: { btn: string }) => item.btn === nameMenu,
    )
  }

  return (
    <>
      <Helmet>
        <title>Gerência de Usuário | Workspace</title>
      </Helmet>
      <Flex flexDirection="column" position="relative" w="full" gap={4}>
        <Flex
          align="center"
          justify="space-between"
          bg="high.pure"
          boxShadow={
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
          }
          borderRadius="4px"
          padding="8px 12px"
        >
          {checkAcessbtn('admin') && (
            <Button
              variant="outline"
              borderColor="primary.pure"
              color="primary.pure"
              leftIcon={
                <Icon as={UserAdd} fontSize="22px" fill="primary.pure" />
              }
              _hover={{ bg: 'gray.100' }}
              onClick={() => navigate('../users/create')}
            >
              Novo usuário
            </Button>
          )}

          <Flex align="center" gap={8} ml="auto">
            <RadioGroup
              name="rad"
              defaultValue="0"
              value={radFilter}
              onChange={setRadFilter}
            >
              <Text color="low.light" fontSize="sm">
                Mostrar:
              </Text>
              <HStack spacing={4}>
                <Radio size="sm" value="0">
                  Ativos
                </Radio>
                <Radio size="sm" value="1">
                  Inativos
                </Radio>
                <Radio size="sm" value="2">
                  Todos
                </Radio>
              </HStack>
            </RadioGroup>
            <InputGroup>
              <Input
                value={textFilter}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  setTextFilter(e.currentTarget.value)
                }
                borderRadius="4px"
                placeholder="Pesquisar"
              />
              <InputLeftElement children={<MagnifyingGlass />} />
            </InputGroup>
          </Flex>
        </Flex>

        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && filteredUsers().length === 0 ? (
          <Center h="full" flexDir="column" gap={4}>
            <NotFindImage />
            <Text fontSize="sm" color="low.light">
              Nada por aqui! Verifique os filtros e tente novamente
            </Text>
          </Center>
        ) : (
          <Box
            bg="high.pure"
            boxShadow={
              '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
            }
            borderRadius="4px"
            overflowY="auto"
          >
            <Table size="sm">
              <Thead position="sticky" top="0" bg="high.pure">
                <Tr h="40px">
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Nome
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    E-mail
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Status
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Perfil
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  ></Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredUsers()?.map((user) => (
                  <Tr key={user.userId.toString()}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      {user.status ? (
                        <Tag fontSize="12px" borderRadius="4px">
                          Ativo
                        </Tag>
                      ) : (
                        <Tag fontSize="12px" borderRadius="4px">
                          Inativo
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      <ProfileTag profileId={user.perfilWs} />
                    </Td>
                    <Td py="0">
                      <IconButton
                        size="sm"
                        position="initial"
                        variant="ghost"
                        aria-label="editar usuário"
                        icon={<UserCircleGear size={22} />}
                        onClick={() => handleSelectUserEditing(user)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </>
  )
}
