import {
  Button,
  ButtonProps,
  Center,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Star } from '@phosphor-icons/react'
import { useState } from 'react'

interface ButtonSubMenuProps extends ButtonProps {
  id?: string
  label: string
  icon?: any
  iconDefault: any
  iconHover: any
  beta?: boolean
  permissions?: string[] | undefined
  action?: () => void
  changeFavorite?: (e: React.MouseEvent<HTMLElement>, param: any) => void
  favorite?: boolean
}

interface IsHoverIconProps {
  icon: string | null
  state: boolean
}

export const ButtonSubMenu = ({
  id,
  label,
  icon,
  iconDefault,
  iconHover,
  beta = false,
  ...props
}: ButtonSubMenuProps) => {
  const [isHoverIcon, setIsHoverIcon] = useState<IsHoverIconProps>({
    icon: null,
    state: false,
  })
  const changeIcon = ({ icon, state }: IsHoverIconProps) => {
    setIsHoverIcon({ icon, state })
  }
  /* btns submenu produtos */
  return (
    <Button
      variant="unstyled"
      sx={{
        width: '100%',
        '&:focus': {
          boxShadow: 'none',
          outline: 'none',
        },
      }}
    >
      <Flex
        width={'100%'}
        height={'40px'}
        onMouseEnter={() => changeIcon({ icon: label, state: true })}
        onMouseLeave={() => changeIcon({ icon: label, state: false })}
        _hover={{ backgroundColor: '#002A66' }}
      >
        <Stack direction={'row'} flex={1} onClick={props.action}>
          <Center w="40px">
            <Text pl={'10px'}>
              {isHoverIcon?.icon === label && isHoverIcon?.state ? (
                <img src={iconHover} alt={label} />
              ) : (
                <img src={iconDefault} alt={label} />
              )}
            </Text>
          </Center>
          <Center flex="1" justifyContent={'flex-start'}>
            <Text
              fontFamily={'title'}
              fontSize={'14px'}
              fontWeight={'normal'}
              color={
                isHoverIcon?.icon === label && isHoverIcon?.state
                  ? 'white'
                  : 'low.dark'
              }
              pl={'4px'}
            >
              {label}
            </Text>
          </Center>
        </Stack>
        <Center w="40px" onClick={(e) => props?.changeFavorite?.(e, id)}>
          {props.favorite ? (
            <Star size={14} color="#f5dd42" weight="fill" />
          ) : (
            <Star size={14} color="#A3A3A3" weight="thin" />
          )}
        </Center>
      </Flex>
    </Button>
  )
}
