import {
  getAplicativosByCompanyDocumentResponseInterface,
  ResponseDataGetAplicativosByCompanyDocumentResponse,
} from '@/services/interfaces/getAplicativosByCompanyDocumentResponse'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { PlusCircle, Question } from 'phosphor-react'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FormInput } from 'src/components/FormInput'
import { FormSelect } from 'src/components/FormSelect'
import { setupProfile } from 'src/constants/setupProfile'
import { useSession } from 'src/contexts'
import {
  apiInstance,
  getAplicativosByCompanyDocument,
  updateUserAppTag,
} from 'src/services/api'
import {
  formatting,
  InputFormattingPattern,
} from 'src/utilities/formattingToPattern'
import { rememberPassword } from 'src/utilities/getEmailHtml'
import { sendBase64ForEmails } from 'src/utilities/sendBase64ForEmails'
import * as yup from 'yup'
import {
  GestorProfiles,
  UniqueProfiles,
  WorkspaceProfiles,
} from '../../../../constants/profiles'
import { profileApps } from '../Create'
import { User } from '../hooks'
import { appsWithPermissions } from '../utils/appsWithPermissions'
import { createdHashRememberPassword } from '../utils/createdPasswordDefault'
import { GetIconApp } from '../utils/getIconApp'
import { getProfileName } from '../utils/getProfileName'

interface UserEdit extends User {
  phone: string
  password?: string
}

const tagsLocais = [
  // 'UN_L0',
  // 'UN_L1',
  // 'UN_L2',
  // 'UN_L3',
  'GE_L4',
  'GE_L3',
  'GE_L2',
  'GE_L1',
]

const schema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: yup
    .string()
    .required('Senha obrigatória(mínimo 8 caracteres)')
    .min(8, 'No mínimo 8 caracteres'),
  re_password: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisão ser iguais'),
})
const schemaNoPassword = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
})

export const EditingUser = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { session } = useSession()
  const navigate = useNavigate()
  const [user, setUser] = useState<UserEdit>()
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [appsSelected, setAppsSelected] = useState<any[]>([])
  const [apps, setApps] = useState<
    ResponseDataGetAplicativosByCompanyDocumentResponse[]
  >([])
  const [profileApps, setProfileApps] = useState<profileApps[]>([])

  const isNotAdmin = session.perfilWs !== 0
  const isSameUser = session.id === user?.userId

  const { profiles, helpLink } = setupProfile({ origin: session.origin })

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm({
    resolver: yupResolver(isChangePassword ? schema : schemaNoPassword),
    shouldFocusError: false,
  })

  const editUser: SubmitHandler<UserEdit> = async (values) => {
    try {
      const appsIsNotAccountedFor: string[] = appsSelected.filter(
        (app: any) => {
          return !appsSelected.includes(app.tag)
        },
      )

      const changedUser = {
        ...user,
        ...(isChangePassword && { password: values.password }),
        name: values.name,
        phone: values.phone.replace(/\D/g, '') || '',
        status: !!Number(values.status),
        perfilWs: Number(values.perfilWs),
        perfilAplicativos: JSON.stringify(profileApps),
        aplicativos: amountStringApps(appsIsNotAccountedFor),
        companyCNPJCPF: session.company.document,
        specific: true,
      }

      await apiInstance(8006).put('UserGestor', changedUser, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      })

      updateUserAppTag({
        userId: user?.userId as number,
        userApps: amountStringApps(appsIsNotAccountedFor) ?? '',
        appTag: amountStringApps(appsIsNotAccountedFor),
        action: 'editing',
        createUser: true,
      })

      toast({
        title: 'Usuário Editado!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })

      navigate(-1)
    } catch (error: any) {
      toast({
        title: 'Falha ao editar usuário!',
        description: 'Não foi possível editar o usuário. Tente novamente!',
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  const amountStringApps = (appsSelected: string[]): string => {
    const appsConverterToString = JSON.stringify(appsSelected)
    let appsConverterToStringReplace = appsConverterToString
      .replaceAll(',', '|')
      .replaceAll('"', '')
      .replaceAll('[', '')
      .replaceAll(']', '')

    return `|${appsConverterToStringReplace}|`
  }

  const confirmToSwitchPassword = (event: FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    setIsChangePassword(checked)
    setValue('password', '')
    setValue('re_password', '')
    clearErrors('re_password')
    clearErrors('password')
  }

  const getAppsByCompany = useCallback(async () => {
    const { data } = (await getAplicativosByCompanyDocument(
      session.company.document,
      session.origin,
    )) as getAplicativosByCompanyDocumentResponseInterface

    setApps(
      data.filter((app: any) => {
        return (
          app.tag !== 'GESTOR' && app.tag !== 'UNIQUE' && app.indAtivar === 'S'
        )
      }),
    )
    return data
  }, [])

  const sendResetPassword = async () => {
    const userData = {
      email: user?.email,
    }

    try {
      await apiInstance(8006).post('User/rememberPassword', userData, {
        headers: {
          Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
        },
      })

      const hashRemenberPassword = await createdHashRememberPassword(
        userData?.email as string,
      )
      const link = `${window.location.origin}/recovery?hash=${hashRemenberPassword}`

      sendBase64ForEmails({
        emails: [userData?.email] as string[],
        htmlFile: rememberPassword(userData?.email as string, link),
      })

      toast({
        title: 'E-mail para alteração de senha enviado',
        description:
          'Verifique a caixa de entrada do e-mail cadastrado e siga os passos para redefinir a senha.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
        duration: 5000,
      })
      navigate(-1)
    } catch (error: any) {
      toast({
        title: 'Falha ao enviar e-mail!',
        description: 'Não foi possível enviar o e-mail. Tente novamente!',
        status: 'error',
        isClosable: true,
        position: 'top-right',
        duration: 5000,
      })
    }
  }

  const handlePatternNameApp = (appTag: string): string => {
    if (appTag.includes('SP_') || appTag.includes('SIMPLAZ')) return 'SIMPLAZ'
    if (appTag.includes('GE_') || appTag.includes('GESTOR')) return 'GESTOR'
    if (appTag.includes('UN_') || appTag.includes('UNIQUE')) return 'UNIQUE'

    return ''
  }

  const handleSelectApp = (appTag: string) => {
    if (handlePatternNameApp(appTag) !== '') {
      appSelectIsNotPermissions(appTag, 'UPDATE')
      return
    }

    if (appsSelected.includes(appTag)) {
      appSelectIsNotPermissions(appTag, 'DELETE')
    } else {
      appSelectIsNotPermissions(appTag, 'INSERT')
    }
  }

  const appSelectIsNotPermissions = (
    appTag: string,
    option: 'INSERT' | 'DELETE' | 'UPDATE',
  ) => {
    switch (option) {
      case 'INSERT':
        setAppsSelected((prevState: any[]) => {
          return [...prevState, appTag]
        })
        break

      case 'DELETE':
        setAppsSelected((prevState: any[]) => {
          return prevState.filter((item) => item !== appTag)
        })
        appsWithPermissions.includes(appTag)
          ? setProfileApps((prevState) => {
              return prevState.filter(
                (profile) =>
                  profile.Aplicativo !== handlePatternNameApp(appTag),
              )
            })
          : console.log()

        break

      case 'UPDATE':
        setAppsSelected((prevState: any[]) => {
          return prevState.filter(
            (item) => item !== appTag && item !== 'GESTOR' && item !== 'UNIQUE',
          )
        })
        setAppsSelected((prevState: any[]) => {
          return [...prevState, appTag]
        })

        break
      default:
        return false
    }
  }

  const handleProfileAppsSelected = (
    appName: 'SIMPLAZ' | 'GESTOR' | 'UNIQUE',
    permission: string | number,
  ) => {
    const app = profileApps.find((app) => app.Aplicativo === appName)

    if (app) {
      setProfileApps((prevState) => {
        return prevState.filter((item) => item.Aplicativo !== appName)
      })
      setProfileApps((prevState) => {
        return [...prevState, { Aplicativo: appName, Perfil: permission }]
      })
    } else {
      setProfileApps((prevState) => {
        return [...prevState, { Aplicativo: appName, Perfil: permission }]
      })
    }
  }

  const isAppActiveToCompany = async () => {
    const appsCompany = await getAppsByCompany()

    const appsActive = appsCompany.filter((app: any) => {
      return (
        app.tag !== 'GESTOR' && app.tag !== 'UNIQUE' && app.indAtivar === 'S'
      )
    })

    // const isActiveApp = appsActive.some((app) => app.tag === appTag)

    const appsUser =
      user?.aplicativos?.split('|').filter((x) => x.length > 1) || []

    setAppsSelected(
      appsUser.filter(
        (app: any) =>
          app !== 'GESTOR' &&
          app !== 'UNIQUE' &&
          appsActive.some((appT) => appT.tag === app),
      ),
    )
  }

  useEffect(() => {
    isAppActiveToCompany()
    // const appsUser =
    //   user?.aplicativos?.split('|').filter((x) => x.length > 1) || []

    // setAppsSelected(
    //   appsUser.filter(
    //     (app: any) =>
    //       app !== 'GESTOR' && app !== 'UNIQUE' && isAppActiveToCompany(app),
    //   ),
    // )
    setProfileApps(
      user?.perfilAplicativos ? JSON.parse(user?.perfilAplicativos) : [],
    )
  }, [user])

  useEffect(() => {
    getAppsByCompany()
  }, [])

  const getNameByApp = (appTag: string) => {
    const app = apps.find((app: any) => app.tag === appTag)
    return app?.nome
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem('ws@user') || '{}')

    if (Object.keys(obj).length === 0) navigate(-1)

    setUser(obj)

    setValue('name', obj.name)
    setValue('perfilWs', obj.perfilWs)
    setValue('email', obj.email)
    setValue(
      'phone',
      formatting({ value: obj.phone || '', pattern: 'phone' }) || '',
    )
    setValue('status', obj.status ? 1 : 0)
    localStorage.removeItem('ws@user')
    return () => localStorage.removeItem('ws@user')
  }, [navigate, setValue])

  return (
    <>
      <Helmet>
        <title>Cadastro de Usuário | Workspace</title>
      </Helmet>
      <Flex
        as="form"
        onSubmit={handleSubmit(editUser)}
        w="full"
        bg="high.pure"
        borderRadius="4px"
        p="5"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        gap={10}
        overflow="auto"
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
        }}
      >
        <Box w="full">
          <Text
            lineHeight="20px"
            mb="8px"
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(22, 112, 215, 1)"
          >
            Dados do usuário
          </Text>
          <Text
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(158, 158, 158, 1)"
          >
            Tenha independência e pague somente por usuários ativos.
          </Text>
          <Flex flexDirection="column" gap={8} mt="16px">
            <FormInput
              {...register('name')}
              error={errors.name}
              label="Nome"
              placeholder="john doe"
              isRequired
            />
            <FormInput
              {...register('email')}
              error={errors.email}
              isDisabled
              label="E-mail"
              placeholder="mail@provider.com"
              isRequired
            />

            <FormInput
              {...register('phone')}
              label="Telefone"
              name="phone"
              placeholder="(00) 0 0000-0000"
              control={{ w: '50%' }}
              onInput={(e) =>
                InputFormattingPattern({ event: e, pattern: 'phone' })
              }
            />

            <Flex gap="4" flexDir="column">
              <FormSelect
                w="210px"
                defaultValue="1"
                label="Status"
                {...register('status')}
                isDisabled={isNotAdmin}
              >
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </FormSelect>

              <FormSelect
                w="318px"
                label="Perfil"
                {...register('perfilWs')}
                defaultValue="2"
                isDisabled={isNotAdmin}
                hus={
                  <Link href={helpLink} target="_blank" ml="8px">
                    <Question size={18} />
                  </Link>
                }
              >
                <option value="0">Administrador</option>
                <option value="1">Usuário</option>
              </FormSelect>
            </Flex>
          </Flex>

          <Box w="full" mt="24px">
            <Button
              bg="transparent"
              border="2px solid #1670D3"
              color="#1670D3"
              fontFamily={'Sora'}
              fontWeight={'600'}
              fontSize={'14px'}
              onClick={sendResetPassword}
            >
              Alterar senha
            </Button>
          </Box>
        </Box>
        <Box w="full">
          <Text lineHeight="20px" mb="8px" color="primary.pure">
            Permissões de uso dos aplicativos
          </Text>
          <Text
            fontSize="16px"
            fontFamily="Sora"
            color="rgba(158, 158, 158, 1)"
          >
            Selecione os aplicativos que esse usuário terá acesso
          </Text>
          <Flex flexDirection="column" gap={8} mt="30px" w="185px">
            <Button
              leftIcon={<PlusCircle size={22} color="#1670D3" />}
              fontFamily="inter"
              fontSize={'14px'}
              fontWeight={'600'}
              color="#1670D3"
              bg="transparent"
              isDisabled={isNotAdmin}
              border="1px solid #1670D3"
              _hover={{
                border: '2px solid #1670D3',
                bg: '#fff',
                color: '#1670D3',
                transform: 'scale(1.02)',
              }}
              h="40px"
              borderRadius="8px"
              onClick={onOpen}
            >
              Ativar aplicativos
            </Button>
          </Flex>
          <Flex flexDir={'column'} mt="24px">
            <Text fontFamily="Sora" fontSize="14px" fontWeight={'400'}>
              Aplicativos ativos
            </Text>
            <Flex
              border="1px solid #CBD5DF"
              mt="4px"
              maxH="200px"
              borderTopLeftRadius={'4px'}
              borderTopRightRadius={'4px'}
              flexDir={'column'}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  background: '#ddd',
                  width: '6px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#A1A1AA',
                  borderRadius: '24px',
                },
              }}
            >
              {appsSelected.length > 0 ? (
                appsSelected
                  .filter((app) => app !== 'GESTOR' && app !== 'UNIQUE')
                  .map((app) => (
                    <Flex
                      key={app.tag}
                      borderBottom="1px solid #CBD5DF"
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Flex flexDir="column" w="400px">
                        <Flex alignItems={'center'}>
                          <Flex>
                            <GetIconApp appTag={app} />
                          </Flex>
                          <Flex flexDir="column">
                            <Text
                              fontSize="14px"
                              fontFamily={'Sora'}
                              fontWeight={'400'}
                              color="#3D374B"
                            >
                              {getNameByApp(app)}
                            </Text>
                            {appsWithPermissions.includes(app) && (
                              <Text
                                fontSize="13px"
                                fontFamily={'Sora'}
                                fontWeight={'400'}
                                color="#666666"
                              >
                                {getProfileName(app, profileApps)}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>

                      <Button
                        color="rgba(240, 62, 62, 1)"
                        fontSize="14px"
                        fontFamily={'Sora'}
                        fontWeight={'400'}
                        bg="transparent"
                        isDisabled={isNotAdmin}
                        _hover={{ bg: 'transparent' }}
                        textDecorationLine={'underline'}
                        onClick={() => appSelectIsNotPermissions(app, 'DELETE')}
                      >
                        Desativar
                      </Button>
                    </Flex>
                  ))
              ) : (
                <Text
                  fontSize="15px"
                  fontFamily={'Sora'}
                  fontWeight={'400'}
                  color="#303030"
                  p="8px"
                >
                  Nenhum aplicativo selecionado
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex gap={10} justify="end" mt="43px">
            <Button
              type="submit"
              minW="156px"
              w="284px"
              h="40px"
              borderRadius="8px"
              color="#FFF"
              fontFamily="inter"
              fontSize={'16px'}
              fontWeight={'700'}
              bg="rgba(16, 153, 114, 1)"
              _hover={{ bg: 'rgba(16, 153, 114, 0.8)', opacity: 0.8 }}
              isDisabled={appsSelected.length === 0}
              isLoading={isSubmitting}
              loadingText="Salvando usúario"
            >
              Salvar alterações
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily="Sora"
            fontSize={'16px'}
            fontWeight={'600'}
            h="40px"
            borderBottom="1px solid #CBD5E0"
          >
            O que deseja ativar?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0">
            <Flex
              flexDir="column"
              overflow="auto"
              maxH="400px"
              css={{
                '&::-webkit-scrollbar': {
                  background: '#ddd',
                  width: '6px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#A1A1AA',
                  borderRadius: '24px',
                },
              }}
            >
              {apps.map((app: any, index: number) => (
                <div key={index}>
                  {appsWithPermissions.includes(app.tag) ? (
                    <Accordion allowToggle key={app.tag}>
                      <AccordionItem>
                        <AccordionButton
                          h="37px"
                          borderBottom="1px solid #CBD5E0"
                          p="0"
                          bg={
                            appsSelected.includes(app.tag) ? '#EAF0F9' : '#FFF'
                          }
                          color={'#303030'}
                          alignItems={'center'}
                          cursor="pointer"
                        >
                          <Flex justifyContent={'space-between'} w="100%">
                            <Flex alignItems={'center'}>
                              <GetIconApp appTag={app.tag} />
                              <Text
                                fontSize="14px"
                                fontFamily={'Sora'}
                                fontWeight={'400'}
                                color="#303030"
                              >
                                {app.nome}
                              </Text>
                            </Flex>
                            <Flex>
                              <AccordionIcon />
                            </Flex>
                          </Flex>
                        </AccordionButton>

                        {app.tag === 'SIMPLAZ' ||
                          (app.tag.includes('SP_') && (
                            <>
                              {WorkspaceProfiles.filter(
                                (app) => app.id !== '999',
                              ).map((workspace) => (
                                <AccordionPanel
                                  key={workspace.id}
                                  p="0"
                                  h="37px"
                                  borderBottom="1px solid #CBD5E0"
                                  bg={
                                    profileApps.some(
                                      (u) =>
                                        u.Aplicativo === 'SIMPLAZ' &&
                                        u.Perfil === workspace.id,
                                    )
                                      ? '#EAF0F9'
                                      : '#FFF'
                                  }
                                  color={'#303030'}
                                  display="flex"
                                  alignItems={'center'}
                                  cursor="pointer"
                                  onClick={() => {
                                    handleSelectApp(app.tag)
                                    handleProfileAppsSelected(
                                      'SIMPLAZ',
                                      workspace.id,
                                    )
                                  }}
                                >
                                  <Text
                                    fontSize="13px"
                                    fontFamily={'Sora'}
                                    fontWeight={'400'}
                                    color="#666666"
                                    ml="57px"
                                  >
                                    Perfil{' '}
                                    {workspace.name.replace(
                                      'Usuário',
                                      'Operacional',
                                    )}
                                  </Text>
                                </AccordionPanel>
                              ))}
                            </>
                          ))}

                        {app.tag.includes('GE_') && (
                          <>
                            {GestorProfiles.filter(
                              (app) => app.id !== '999',
                            ).map((gestor) => (
                              <AccordionPanel
                                key={gestor.id}
                                p="0"
                                h="37px"
                                borderBottom="1px solid #CBD5E0"
                                bg={
                                  profileApps.some(
                                    (u) =>
                                      u.Aplicativo === 'GESTOR' &&
                                      u.Perfil === gestor.id,
                                  )
                                    ? '#EAF0F9'
                                    : '#FFF'
                                }
                                color={'#303030'}
                                display="flex"
                                alignItems={'center'}
                                cursor="pointer"
                                onClick={() => {
                                  handleSelectApp(app.tag)
                                  handleProfileAppsSelected('GESTOR', gestor.id)
                                }}
                              >
                                <Text
                                  fontSize="13px"
                                  fontFamily={'Sora'}
                                  fontWeight={'400'}
                                  color="#666666"
                                  ml="57px"
                                >
                                  Perfil {gestor.name}
                                </Text>
                              </AccordionPanel>
                            ))}
                          </>
                        )}

                        {app.tag.includes('UN_') && (
                          <>
                            {UniqueProfiles.filter(
                              (app) => app.id !== '999',
                            ).map((unique) => (
                              <AccordionPanel
                                key={unique.id}
                                p="0"
                                h="37px"
                                borderBottom="1px solid #CBD5E0"
                                bg={
                                  profileApps.some(
                                    (u) =>
                                      u.Aplicativo === 'UNIQUE' &&
                                      u.Perfil === unique.id,
                                  )
                                    ? '#EAF0F9'
                                    : '#FFF'
                                }
                                color={'#303030'}
                                display="flex"
                                alignItems={'center'}
                                cursor="pointer"
                                onClick={() => {
                                  handleSelectApp(app.tag)
                                  handleProfileAppsSelected('UNIQUE', unique.id)
                                }}
                              >
                                <Text
                                  fontSize="13px"
                                  fontFamily={'Sora'}
                                  fontWeight={'400'}
                                  color="#666666"
                                  ml="57px"
                                >
                                  Perfil {unique.name}
                                </Text>
                              </AccordionPanel>
                            ))}
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <Flex
                      key={app.tag}
                      h="37px"
                      borderBottom="1px solid #CBD5E0"
                      bg={appsSelected.includes(app.tag) ? '#EAF0F9' : '#FFF'}
                      color={'#303030'}
                      alignItems={'center'}
                      cursor="pointer"
                      onClick={() => {
                        handleSelectApp(app.tag)
                      }}
                    >
                      <GetIconApp appTag={app.tag} />
                      <Text
                        fontSize="14px"
                        fontFamily={'Sora'}
                        fontWeight={'400'}
                        color="#303030"
                      >
                        {app.nome}
                      </Text>
                    </Flex>
                  )}
                </div>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
