import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { SponserInterface } from '..'

export const CardSponser = ({
  aplicativo,
  descricao,
  imagem,
  linkBotao,
  textoBotao,
  tituloImagem,
}: SponserInterface) => {
  const openLink = (link: string) => {
    window.open(link, '_blank')
  }

  return (
    <Box     
      sx={{        
        minW: '265px',        
        width: '100%',             
        bgColor: '#ffff',
        borderRadius: '4px',        
      }}  
      boxShadow={
        '0px 0px 0px 0px rgba(39, 35, 46, 0.10), 0px 0px 1px 0px rgba(39, 35, 46, 0.10), 0px 2px 2px 0px rgba(39, 35, 46, 0.09), 0px 4px 2px 0px rgba(39, 35, 46, 0.05), 0px 7px 3px 0px rgba(39, 35, 46, 0.01), 0px 11px 3px 0px rgba(39, 35, 46, 0.00)'
      }
    >
      <Flex justifyContent={'center'} 
        sx={{
          width: '100%',
          padding: '24px',      
        }}        
      >
        <Image
          src={imagem}
          alt={tituloImagem}
          borderRadius="8px"
          w={'100%'}
          height={'auto'}
        />
      </Flex>
      <Flex 
        sx={{
          pt: '19px',
          display: 'flex',
          justifyContent: 'center',
          flexDir: 'column',
          width: '100%',
          padding:'0 24px 24px 24px'
        }}>
        <Text
          fontFamily="inter"
          fontSize="20px"
          fontWeight={'700'}
          color="#006AFF"
        >
          {tituloImagem}
        </Text>
        <Text
          fontFamily="inter"
          fontSize="13px"
          fontWeight={'400'}
          color="#292929"
          mt="10px"
        >
          {descricao}
        </Text>
        <Button
          h="44px"
          maxW="200px"
          mt="24px"
          bg="#1155BB"
          _hover={{ opacity: 0.8 }}
          boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
          borderRadius="8px"
          color="#fff"
          fontFamily="inter"
          fontSize="14px"
          fontWeight={'700'}
          onClick={() => openLink(linkBotao)}
        >
          {textoBotao}
        </Button>
      </Flex>
    </Box>
  )
}
