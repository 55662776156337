import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSession } from 'src/contexts'
import {
  replaceTextDescrypt,
  replaceTextEncrypt
} from 'src/screens/Importador/utils/replaceTextIcorrects'
import { getCompanyConfiguration, updateToInactivate } from 'src/services/api'
import { decryptedSimple, encryptedSimple } from 'src/utilities/hashing'
import EditFileSVG from '../../../../../assets/icons/editfile.svg'
import RemoveSVG from '../../../../../assets/icons/removeicon.svg'
import { Configuration } from '../../../types/types'
import { Data } from '../correlationPage'

let trash: any = []

const CorrelationSaved = () => {
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState<Configuration[]>()
  const [receive, setReceive] = useState<string[]>()
  const [ativo, setAtivo] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { session } = useSession()
  const navigate = useNavigate()

  const { path } = useParams()
  const decodePath = replaceTextDescrypt(path!)

  const decryptData: Data = decryptedSimple(decodePath!)
  let { head, table } = decryptData

  useEffect(() => {
    handleSaveConfiguration()
  }, [])

  const [selectCorrelation, setSelectCorrelation] = useState<string>('')

  const correlationDecript = atob(selectCorrelation)

  const handleSaveConfiguration = async () => {
    try {
      setLoading(true)
      const data = await getCompanyConfiguration(
        session.company.document,
        session.company.id
      )

      const onlyListCorrelations = data.filter(
        (item: any) =>
          item.key === 'hub-correlation' &&
          item.value !== null &&
          item.inativo === 0
      )

      const descriptBase64 = onlyListCorrelations.map((item: any) =>
        atob(item.value)
      )

      const names = descriptBase64.map((item: any) => JSON.parse(item))
      const jsonTransform = names.map((item: any) => item)

      setReceive(jsonTransform.map((item: any) => item.nameCorrelation))

      const dados = onlyListCorrelations
        .filter((item: any, index: any) => item.inativo === 0)
        .map((item: any, index: any) => {
          return {
            ...item,
            nameCorrelation: jsonTransform[index].nameCorrelation,
          }
        })

      setUpdate(dados)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async () => {
    const payload = {
      configurationId: trash.configurationId,
      companyId: trash.companyId,
      userId: trash.userId,
      origin: trash.origin,
      value: trash.value,
      key: trash.key,
      dateCreated: trash.dateCreated,
      inativo: 1,
    }

    try {
      await updateToInactivate(
        session.company.document,
        trash.configurationId,
        payload
      )
      setAtivo(false)
    } catch (err) {
      console.log(err)
      setAtivo(true)
    }
  }

  return (
    <>
      <Flex w={'100%'} h={'100%'} flexDir="column" fontFamily={'Inter'}>
        <Flex w={'100%'} h={'20%'} justifyContent="space-between">
          <Flex justifyContent={'space-between'}>
            <Image src={EditFileSVG} alt="editfile" width={'14%'} />
            <Flex alignItems={'center'}>
              <Text
                fontWeight={'700'}
                fontSize="18px"
                color={'#58595B'}
                ml="15px"
              >
                Selecione a correlação
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          h="417.71px"
          bg="#F1F6FF"
          borderRadius="6px"
          border="2px solid #e1e0e7"
          overflowY={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              background: '#ddd',
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              width: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A1A1AA',
              borderRadius: '24px',
            },
            thead: {
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },
          }}
        >
          {loading ? (
            <Flex margin={'auto'}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          ) : (
            <Flex m="auto" w="80%">
              <Flex>
                <RadioGroup>
                  <Flex
                    flexWrap={'wrap'}
                    w="100%"
                    justifyContent={'space-between'}
                    flexDir={'row'}
                  >
                    {update?.map((item, index) => (
                      <Flex
                        bg={'#3971C6'}
                        w="454px"
                        h={'92px'}
                        borderRadius="6px"
                        justifyContent={'space-between'}
                        mt={'24px'}
                        key={index}
                        onClick={() => setSelectCorrelation(item.value)}
                      >
                        <Radio
                          borderRadius="4px"
                          bg={'#FFFFFF'}
                          size="lg"
                          value={item.value}
                          justifyContent={'space-between'}
                          ml="10%"
                        >
                          <Flex
                            alignItems={'center'}
                            justifyContent="space-between"
                            w="355px"
                            ml={'27px'}
                          >
                            <Flex flexDirection={'column'}>
                              <Text
                                color={'#FFFFFF'}
                                fontFamily="Inter"
                                fontSize="18px"
                                fontWeight={'700'}
                              >
                                {item.nameCorrelation}
                              </Text>
                              <Text
                                color={'#FFFFFF'}
                                fontFamily="Inter"
                                fontSize="16px"
                                fontWeight={'400'}
                              >
                                Salvo em:{' '}
                                {new Date(
                                  item.dateCreated
                                ).toLocaleDateString()}{' '}
                                às{' '}
                                {new Date(
                                  item.dateCreated
                                ).toLocaleTimeString()}
                              </Text>
                            </Flex>
                            <Flex>
                              <Button
                                bg={'#2F80ED'}
                                _hover={{ bg: '#006AFF' }}
                                w="78px"
                                h={'92px'}
                                onClick={() => {
                                  onOpen()
                                  trash = item
                                }}
                              >
                                <Image src={RemoveSVG} alt="remove" />
                              </Button>
                            </Flex>
                          </Flex>
                        </Radio>
                      </Flex>
                    ))}
                  </Flex>
                </RadioGroup>
              </Flex>

              <Modal isOpen={isOpen} onClose={onClose} isCentered size={'lg'}>
                <ModalOverlay />
                <ModalContent borderRadius={'12px'}>
                  <ModalHeader fontSize={'32px'}>
                    Deletar a correlação?
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    A ação não poderá ser desfeita. Você terá que correlacionar
                    novamente se desejar usar no futuro.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      bg="transparent"
                      onClick={onClose}
                      fontWeight="500"
                      fontSize={'14px'}
                      w="106px"
                      h={'52px'}
                      mr="16px"
                    >
                      Cancelar
                    </Button>
                    <Button
                      w="106px"
                      h={'52px'}
                      bg={'#F03E3E'}
                      borderRadius="4px"
                      onClick={() => {
                        {
                          onClose()
                          handleDelete()
                        }
                      }}
                    >
                      <Text color={'#fff'} fontWeight="700" fontSize={'16px'}>
                        Deletar
                      </Text>
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
          )}
        </Flex>

        <Flex w={'100%'} justifyContent="flex-end" mt="34px">
          <Link
            to={`/importa-facil/correlation/${replaceTextEncrypt(
              encryptedSimple({
                head: head,
                table: table,
                valueCorrelation:
                  selectCorrelation !== '' ? selectCorrelation : '',
              })
            )}`}
          >
            <Button
              w="227px"
              h={'52px'}
              bg="transparent"
              border="1px solid #1155BB"
              color="#1155BB"
              _hover={{ bg: 'transparent' }}
              borderRadius={'4px'}
              fontSize="16px"
              fontWeight={'700'}
              disabled={selectCorrelation === ''}
            >
              Editar correlação salva
            </Button>
          </Link>

          <Link
            to={`/importa-facil/selectpage/${replaceTextEncrypt(
              encryptedSimple({
                table: table,
                returnTable: correlationDecript
                  ? JSON.parse(correlationDecript).arrayView
                  : '',
              })
            )}`}
          >
            <Button
              w="170px"
              h={'52px'}
              bg="#1155BB"
              _hover={{ bg: '#006AFF' }}
              color="#fff"
              borderRadius={'4px'}
              fontSize="16px"
              fontWeight={'700'}
              ml="20px"
              disabled={selectCorrelation === ''}
            >
              Continuar
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  )
}

export default CorrelationSaved
