import { postGravarLogRequest } from '@/services/interfaces/postGravarLogRequest'
import {
  Button,
  Fade,
  Flex,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet-async'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as WorkspaceLogo } from 'src/assets/workspace_horizontal.svg'
import { Input } from 'src/components/Form/Input'
import { Slider } from 'src/components/SliderLogin'
import { useSession } from 'src/contexts'
import { apiInstance, postGravarLog } from 'src/services/api'
import { encrypted } from 'src/utilities/hashing'
import * as yup from 'yup'
import { CheckAccessBtn } from 'src/constants/hooks/checkAccessBtn'

interface Credentials {
  email: string
  password: string
}

interface PermissionLevelProps {
  id: string
  type: string
  level: string[]
}

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail inválido')
    .required('E-mail obrigatório')
    .trim(),
  password: yup.string().required('Senha obrigatória'),
})

const appsNotAllowed = ['INTELVENDAS', 'INTELADM']
const clearPermissions = (companyApps: string): string => {
  return companyApps
    .split('|')
    .filter((app) => {
      if (!appsNotAllowed.some((appNotAllowed) => appNotAllowed === app))
        return app
    })
    .join('|')
}

export const Login = () => {
  const toast = useToast()
  const { handleSetSession } = useSession()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Credentials>({
    resolver: yupResolver(loginSchema),
    shouldFocusError: false,
  })

  const handleLogin: SubmitHandler<Credentials> = async ({
    email,
    password,
  }) => {
    try {
      // const { data: user } = await apiInstance(8006).post('/Authetication', {
      //   email,
      //   password,
      // })

      const { data: user } = await apiInstance(8006).post('/Authetication', {
        email,
        password,
      })

      if (!user.status) {
        toast({
          title: 'Acesso não habilitado!',
          status: 'info',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        })
        return
      }

      // if (user.firstOrigin === 'Unique' && user.perfilId !== 1) {
      //   toast({
      //     title: 'Acesso não permitido!',
      //     description: 'Acesso a usuários do unique não adms bloqueado 😎',
      //     status: 'info',
      //     duration: 4000,
      //     isClosable: true,
      //     position: 'top-right',
      //   })
      //   return
      // }

      const { data: company } = await apiInstance(8006).get(
        `Company/${user.companies[0].companyId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            companyId: user.companies[0].companyId,
          },
        },
      )

      const { data } = await apiInstance(9000)
        .get(`/workspace/Empresa/${company.CnpjCpf}`, {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        })
        .catch((err) => {
          throw new Error(
            'Sem informações da aplicação, entre em contato com o departamento de atendimento!',
          )
        })

      const {
        data: { accessToken },
      } = await apiInstance(9008).post('/api/login', {
        login: 'consumoapi@londrisoft.com.br',
        password: 'ConsApi@2022%',
      })

      const {
        data: { company: plan },
      } = await apiInstance(9008).get(`/api/Empresa?cnpj=${company.CnpjCpf}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      const companyApps = user.aplicativos as string

      let permissions: string[] = clearPermissions(companyApps).split('|')

      if (user.aplicativos) {
        if (!appsNotAllowed.some((x) => companyApps.includes(x))) {
          permissions = permissions.concat(
            clearPermissions(user.aplicativos)
              .split('|')
              .filter((x: string) => x.length > 0),
          )
        } else {
          permissions = permissions.concat(
            user.aplicativos.split('|').filter((x: string) => x.length > 0),
          )
        }
      }

      const isGestor = user.aplicativos.includes('GE_')

      // const originUser = data.company.aplicativos
      //   .split('|')
      //   .filter((x: string) => x.length > 0)
      //   .filter((x: string) => x === 'GESTOR' || x === 'UNIQUE')

      if (user.perfilWs === 0) {
        permissions = [...permissions, 'ADMIN']
      }
      if (user.type === 1) {
        permissions = [...permissions, 'CLOUD']
      }

      // verifica permissoes de btn
      const btnsAcess = await CheckAccessBtn(permissions)

      const newSession = {
        id: user.userId,
        name: user.name,
        email: user.email,
        perfil: user.perfilId,
        perfilWs: user.perfilWs,
        token: user.token,
        type: user.type,
        specific: user.specific,
        origin: isGestor ? 'Gestor' : 'Unique',
        permissions: encrypted(permissions),
        btnsMenu: btnsAcess,
        company: {
          name: company.FictitiousName,
          id: company.CompanyId,
          server: company.Nserver,
          document: company.CnpjCpf,
          status: company.Status,
          address: {
            cep: data.company.cep,
            street: data.company.endereco,
            city: data.company.cidade,
            state: data.company.estado,
            neighborhood: data.company.bairro,
            number: data.company.numero,
          },
          complemento: data.complemento,
          responsible: data.company.responsavel,
          phone: data.company.fone,
          email: data.company.email,
        },
        plan,
      }

      const log: postGravarLogRequest = {
        EmpresaCnpj: newSession.company.document,
        IdUsuario: newSession.id,
        NomeUsuario: newSession.name,
        Aplicativo: 'Workspace',
        Acao: 'Login Workspace',
        TipoAcao: 'Login',
        EndPoint: '',
      }

      await postGravarLog(log)

      handleSetSession(newSession)
      navigate('/initial')
    } catch (error: any) {
      if (!error.response) {
        toast({
          description: error.message,
          status: 'warning',
          duration: 3500,
          isClosable: true,
          position: 'top-right',
        })
        return
      }

      toast({
        position: 'top-right',
        title: 'Conta não localizada!',
        description: 'Verifique seu  e-mail e senha.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Fade in={true} delay={0.2}>
      <Helmet>
        <title>Login | Workspace</title>
      </Helmet>
      <Flex h={'100vh'}>
        <Flex
          onSubmit={handleSubmit(handleLogin)}
          as="form"
          minWidth={{ base: '100%', lg: '640px' }}
          width={{ base: '100%', lg: '80%' }}
          alignItems={'center'}
          justifyContent={'center'}
          px={{ base: '10px', lg: '0' }}
        >
          <Flex
            flexDirection={'column'}
            width={'400px'}
            maxHeight={'700px'}
            height={{ '2xl': '60%', lg: '80%' }}
            padding={'24px 0 24px 0'}
          >
            <WorkspaceLogo style={{ alignSelf: 'center' }} />
            <Stack spacing={'30px'} mt="57px">
              <Input
                {...register('email')}
                error={errors.email}
                label="E-mail do usuário"
                placeholder="email@exemplo.com"
              />
              <Input
                {...register('password')}
                error={errors.password}
                label="Senha"
                placeholder="***********"
                type={'password'}
              />
              <Flex flexDirection={'column'} alignItems={'center'}>
                <Button
                  alignSelf={'center'}
                  colorScheme={'blue'}
                  width={'176px'}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Acessar
                </Button>
                <Button
                  variant={'link'}
                  mt={'12px'}
                  fontSize="14px"
                  fontWeight={'medium'}
                  color="primary.pure"
                  textAlign="center"
                  onClick={() => navigate('/recovery')}
                >
                  Esqueceu sua senha?
                </Button>
              </Flex>
            </Stack>
            <Text
              fontSize={'14px'}
              color={'low.light'}
              mt={{ base: '60px', lg: 'auto' }}
            >
              Problemas para acessar? Acesse nossa{' '}
              <Link
                color={'primary.pure'}
                fontWeight={'bold'}
                href="https://londrisoft.movidesk.com/kb/pt-br"
                target="_blank"
              >
                Central de Ajuda
              </Link>
            </Text>
          </Flex>
        </Flex>
        <Slider />
      </Flex>
    </Fade>
  )
}
