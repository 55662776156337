import { useSession } from "src/contexts";
import { Box, Divider, List, ListItem, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FavoriteObj } from "../types/types";
import { ItemsSubmenuProduct } from "./ItemsSubmenuProduct";

interface ButtonProps {
  id: string;
  label: string;
  icon: string;
  action: () => void;
}

interface ButtonsFavoriteProps {
  btnsFavorite: FavoriteObj;
  activeMenu: string | null;
  setActiveMenu: (value: string | null) => void;
  isFullPage: boolean;
}

export default function ButtonsFavorite({
  btnsFavorite,
  activeMenu,
  setActiveMenu,
  isFullPage,
}: Readonly<ButtonsFavoriteProps>) {
  const itemsProduct = ItemsSubmenuProduct();
  const [existsItems, setExistsItems] = useState<boolean>(false);

  const { session } = useSession();
  const sessionBtnAccess = session.btnsMenu;

  const returnBtnFavorite = (tag: string, status: boolean) => {
    let button: ButtonProps | null = {
      id: '',
      label: '',
      icon: '',
      action: () => {},
    };
    switch (tag) {
      case "gestor":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[0]?.id)
          ) {
            button = {
              id: itemsProduct.product[0]?.id,
              label: itemsProduct.product[0]?.label,
              icon: itemsProduct?.product[0]?.iconFavorite,
              action: () => itemsProduct?.product[0]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "unique":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[1]?.id)
          ) {
            button = {
              id: itemsProduct.product[1]?.id,
              label: itemsProduct.product[1]?.label,
              icon: itemsProduct?.product[1]?.iconFavorite,
              action: () => itemsProduct?.product[1]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "simplaz":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[2]?.id)
          ) {
            button = {
              id: itemsProduct.product[2]?.id,
              label: itemsProduct.product[2]?.label,
              icon: itemsProduct?.product[2]?.iconFavorite,
              action: () => itemsProduct?.product[2]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "analytics":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[3]?.id)
          ) {
            button = {
              id: itemsProduct.product[3]?.id,
              label: itemsProduct.product[3]?.label,
              icon: itemsProduct?.product[3]?.iconFavorite,
              action: () => itemsProduct?.product[3]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "analyticspro1":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[4]?.id)
          ) {
            button = {
              id: itemsProduct.product[4]?.id,
              label: itemsProduct.product[4]?.label,
              icon: itemsProduct?.product[4]?.iconFavorite,
              action: () => itemsProduct?.product[4]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "analyticspro2":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[5]?.id)
          ) {
            button = {
              id: itemsProduct.product[5]?.id,
              label: itemsProduct.product[5]?.label,
              icon: itemsProduct?.product[5]?.iconFavorite,
              action: () => itemsProduct?.product[5]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "clubedocontador":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[6]?.id)
          ) {
            button = {
              id: itemsProduct.product[6]?.id,
              label: itemsProduct.product[6]?.label,
              icon: itemsProduct?.product[6]?.iconFavorite,
              action: () => itemsProduct?.product[6]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "importafacil":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[7]?.id)
          ) {
            button = {
              id: itemsProduct.product[7]?.id,
              label: itemsProduct.product[7]?.label,
              icon: itemsProduct?.product[7]?.iconFavorite,
              action: () => itemsProduct?.product[7]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      case "quod":
        if (status === true) {
          if (
            sessionBtnAccess.length > 0 &&
            checkButtonAccess(itemsProduct.product[8]?.id)
          ) {
            button = {
              id: itemsProduct.product[8]?.id,
              label: itemsProduct.product[8]?.label,
              icon: itemsProduct?.product[8]?.iconFavorite,
              action: () => itemsProduct?.product[8]?.action(),
            };
          } else {
            button = null;
          }
        }
        break;

      default:
        return null;
    }

    if (button) {
      return (
        <ListItem
          key={tag}
          fontFamily={"inter"}
          className={`listMenuItem ${
            button.id === activeMenu ? "activeMenu" : ""
          }`}
          onClick={() => updateActiveMenu(button?.id, button?.action())}
        >
          <Tooltip
            label={button.label}
            bg="gray.400"
            hasArrow
            placement="right"
            isDisabled={isFullPage}
          >
            <Box className="listmenuItemBox">
              <Box className="text">
                <img src={button.icon} alt="icon" />

                {isFullPage && <Text>{button.label}</Text>}
              </Box>
            </Box>
          </Tooltip>
        </ListItem>
      );
    } else {
      return null;
    }
  };

  const checkFavoriteAccess = (tag: string, status: boolean) => {
    if (!status) return false;

    switch (tag) {
      case "gestor":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[0]?.id)
        );
      case "unique":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[1]?.id)
        );
      case "simplaz":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[2]?.id)
        );
      case "analytics":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[3]?.id)
        );
      case "analyticspro1":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[4]?.id)
        );
      case "analyticspro2":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[5]?.id)
        );
      case "clubedocontador":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[6]?.id)
        );
      case "importafacil":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[7]?.id)
        );
      case "quod":
        return (
          sessionBtnAccess.length > 0 &&
          checkButtonAccess(itemsProduct.product[8]?.id)
        );
      default:
        return false;
    }
  };

  const updateActiveMenu = (
    nameMenu: string | undefined,
    onclick: any = null
  ) => {
    setActiveMenu(nameMenu || null);
  };

  const checkButtonAccess = (nameMenu: string) => {
    return !!sessionBtnAccess.find(
      (item: { btn: string }) => item.btn === nameMenu
    );
  };

  useEffect(() => {
    if (btnsFavorite?.tag) {
      const entries = Object.entries(btnsFavorite?.tag);
      let index = 0;
      while (index < entries.length) {
        if (
          entries[index][1] &&
          !!checkFavoriteAccess(entries[index][0], entries[index][1])
        ) {
          setExistsItems(true);
          break;
        } else {
          setExistsItems(false);
        }
        index++;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnsFavorite]);  

  return (
    <>
      {existsItems ? (
        <Box className="favorites">
          <Box className="title">{isFullPage && <Text>Favoritos</Text>}</Box>
          <List className="listMenu">
            {btnsFavorite?.tag &&
              Object.entries(btnsFavorite?.tag)
                .filter(([key, value]) => value)
                .map(([key, value]) => returnBtnFavorite(key, value))}
          </List>
          <Box sx={{ pl: "24px", pr: "24px", pt: "20px" }}>
            <Divider borderColor={"#CCE1FF"} />
          </Box>
        </Box>
      ) : null}
    </>
  );
}
