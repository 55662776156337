import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import ProductFruits from 'react-product-fruits'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { ModalLogout } from 'src/components/Logout'
import { apiInstance, getUserByUserId } from 'src/services/api'
import { encrypted } from 'src/utilities/hashing'
import { CheckAccessBtn } from 'src/constants/hooks/checkAccessBtn'

type Props = {
  children: React.ReactNode
}

interface SessionContextData {
  session: Session
  handleLogout: () => void
  openLogoutModal: () => void
  handleSetSession: (newSession: Session) => void
  attPermissions: () => Promise<void>
  Certificate: any
}

const SessionContext = createContext({} as SessionContextData)

export function SessionProvider({ children }: Props) {
  const navigate = useNavigate()
  const [close, setClose] = useState(false)
  const [session, setSession] = useState<Session>(() => {
    const session = secureLocalStorage.getItem('ws@session')
    if (session) {
      return JSON.parse(session as string)
    }
    return {} as SessionContextData
  })

  const handleSessionPage = async () => {
    // sempre que tiver 1 quer dizer a pessoa esta com a sessao ativa

    if (!sessionStorage.getItem('ws@session-validated')) {
      const userId = session.id

      const user = await getUserByUserId(userId)

      if (user.status !== true) {
        handleLogout()
        return
      }

      const appsNotAllowed = ['INTELVENDAS', 'INTELADM']
      const clearPermissions = (companyApps: string): string => {
        return companyApps
          .split('|')
          .filter((app) => {
            if (!appsNotAllowed.some((appNotAllowed) => appNotAllowed === app))
              return app
          })
          .join('|')
      }

      const companyApps = user.aplicativos as string

      let permissions: string[] = clearPermissions(companyApps).split('|')

      if (user.aplicativos) {
        if (!appsNotAllowed.some((x) => companyApps.includes(x))) {
          permissions = permissions.concat(
            clearPermissions(user.aplicativos)
              .split('|')
              .filter((x: string) => x.length > 0),
          )
        } else {
          permissions = permissions.concat(
            user.aplicativos.split('|').filter((x: string) => x.length > 0),
          )
        }
      }

      const originUser = user.aplicativos
        .split('|')
        .filter((x: string) => x.length > 0)
        .filter((x: string) => x === 'GESTOR' || x === 'UNIQUE')

      if (user.perfilWs === 0) {
        permissions = [...permissions, 'ADMIN']
      }
      if (user.type === 1) {
        permissions = [...permissions, 'CLOUD']
      }

      // verifica permissoes de btn
      const btnsAcess = await CheckAccessBtn(permissions)

      handleSetSession({
        ...session,
        permissions:encrypted(permissions),
        btnsMenu:btnsAcess
      })
      sessionStorage.setItem('ws@session-validated', '1')
    }
  }

  const handleSetSession = (newSession: Session) => {
    secureLocalStorage.setItem('ws@session', JSON.stringify(newSession))
    localStorage.setItem('@wk-version', '2.1.0')
    setSession(() => newSession)
  }

  const openLogoutModal = () => setClose(true)

  const handleLogout = useCallback(() => {
    setClose(false)
    sessionStorage.clear()
    secureLocalStorage.removeItem('ws@session')
    localStorage.removeItem('ws@groups')
    setSession({} as any)
    navigate('/')
  }, [navigate])

  const attPermissions = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/Empresa/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )

      let attPermissions: String[] = data.company.aplicativos
        .split('|')
        .filter((app: string) => app.length !== 0) || ['']

      if (session.perfilWs === 0) {
        attPermissions = [...attPermissions, 'ADMIN']
      }
      if (session.type === 1) {
        attPermissions = [...attPermissions, 'CLOUD']
      }

      const attSession: Session = {
        ...session,
        permissions: encrypted(attPermissions),
      }
      localStorage.setItem('ws@session', JSON.stringify(attSession))
      setSession(() => attSession)
    } catch (error) {
      console.log(error)
    }
  }, [session])

  const Certificate = () => {
    const [certificate, setCertificate] = useState('')
    const getCertificate = useCallback(async () => {
      try {
        const { data } = await apiInstance(9000).get(
          `/workspace/Certificado/Vencimento/${session.company.document}`,
          {
            headers: {
              GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
            },
          },
        )

        setCertificate(new Date(data.dados.vencimento).toLocaleDateString())
      } catch (error) {}
    }, [session])

    useEffect(() => {
      getCertificate()
    }, [getCertificate])
    return certificate
  }

  let datacertificate = ''
  datacertificate = Certificate()

  let ldata_completed = 'true'

  const data_validation = async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/Empresa/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )

      const x2: Complemento = data.complemento

      if (
        x2.indRegime === 0 ||
        x2.indAtividade === 0 ||
        x2.csosn === 0 ||
        x2.csosnNfce === 0 ||
        x2.qtColaboradores === 0 ||
        x2.faturamentoAno === 0 ||
        x2.cnae === 0 ||
        x2.indSegComercio +
          x2.indSegEcommerce +
          x2.indSegServico +
          x2.indSegIndustria ===
          0 ||
        x2.indIpi + x2.indNfe + x2.indNfce + x2.indNfse === 0
      ) {
        ldata_completed = 'false'
        return 'false'
      } else {
        ldata_completed = 'true'
        return 'true'
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fruitsCode =
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('alpha')
      ? process.env.REACT_APP_FRUITS_ALPHA
      : process.env.REACT_APP_FRUITS_PROD

  const userInfo = {
    username: session.name || 'wk',
    email: session?.email || '',
    role: session?.perfil || '',
    props: {
      isdatacompleted: ldata_completed || '',
      system: session?.origin || '',
      type: session.type === 0 ? 'local' : 'cloud',
      plan: session.plan?.aplicativos || '',
      financialstatus:
        session.plan?.indfinanceiro === '0' ? 'normal' : 'pendente',
      certificateexpiration: datacertificate,
    },
  }

  useEffect(() => {
    const version = localStorage.getItem('@wk-version')
    handleSessionPage()

    if (!version) {
      handleLogout()
    }
  }, [handleLogout])

  return (
    <SessionContext.Provider
      value={{
        handleLogout,
        session,
        openLogoutModal,
        handleSetSession,
        attPermissions,
        Certificate,
      }}
    >
      <>
        {children}
        <ProductFruits projectCode={fruitsCode} language="pt" {...userInfo} />
        <ModalLogout isOpen={close} action={() => setClose(false)} />
      </>
    </SessionContext.Provider>
  )
}

export const useSession = (): SessionContextData => useContext(SessionContext)

type Address = {
  cep: string
  street: string
  city: string
  state: string
  neighborhood: string
  number: string
}

type Complemento = {
  complementoId: number
  empresaId: number
  hashEmpresa: string
  cnpjCpfEmpresa: string
  fantasia: string
  insEstadual: string
  insMunicipal: string
  indRegime: number
  indAtividade: number
  indClasstrib: number
  naturezaJuridica: string
  csosn: number
  csosnNfce: number
  qtColaboradores: number
  faturamentoAno: number
  cnae: number
  descricaoCnae: string
  cnae2: number
  descricaoCnae2: string
  cnae3: number
  descricaoCnae3: string
  cnae4: number
  descricaoCnae4: string
  cnae5: number
  descricaoCnae5: string
  indSegComercio: number
  indSegEcommerce: number
  indSegServico: number
  indSegIndustria: number
  indIpi: number
  indNfe: number
  indNfce: number
  indNfse: number
  atuacaoEmpresa: string
  dtCriacao: Date
  dtAtualiza: Date
}

type Plan = {
  empresaId: number
  razao: string
  indfinanceiro: string
  indStatus: string
  cnpjCpf: string
  email: string
  planoId: number
  planoGestorId: number
  planoUniqueId: number
  nrSerieUnique: string
  nrSerieGestor: string
  aplicativos: string
  versao: string
  dtBloqGestor: string
  dtBloqUnique: string
  link: string
  powerBiGroupId: string
  powerBiReportId: string
  powerBiHora: string
  versaoSistema: string
  indPlano: string
  users: string
}

export interface Session {
  id: number
  name: string
  email: string
  perfil: number
  perfilWs: number
  type: number
  token: string
  specific: boolean
  origin: string
  permissions?: number[]
  btnsMenu: any
  plan: Plan
  company: {
    name: string
    id: number
    server: number
    status: number
    document: string
    responsible: string
    phone: string
    email: string
    address?: Address
    complemento?: Complemento
  }
}
