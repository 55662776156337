import {
  Checkbox,
  Flex,
  Image,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { mask } from 'src/utilities/maskCnpjCpf'

import Download_Boleto from 'src/assets/icons/download-boleto.svg'

import { Badge } from './Badge'

interface IBoletos {
  cnpjCpf: string
  email: string
  dtVencimento: string
  valor: string
  dtPagamento: string
  boleto: string
  nota: string
}

interface TableIndexProps {
  listInfoBoleto: IBoletos[]
}

export const TableIndex = ({ listInfoBoleto }: TableIndexProps) => {
  const handleDownlaodBoleto = (boleto: string) => {
    window.open(boleto, '_blank')
  }

  const getDateYearLesserThan1900 = (date: string) => {
    const dateYearLesserThan1900 = date.split('-')

    return parseInt(dateYearLesserThan1900[0]) <= 1901
      ? ''
      : new Date(date).toLocaleDateString()
  }

  return (
    <Flex
      w="1140px"
      // sm, md, lg, xl, 2xl
      mt="24px"
      border="1px solid #EAECF0"
      boxShadow={
        '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
      }
      borderRadius={'8px'}
    >
      <TableContainer
        w="100%"
        h={[300, 300, 300, 300, 476]}
        maxH={[300, 300, 300, 300, 476]}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
          thead: {
            position: 'sticky',
            top: 0,
            zIndex: 2,
          },
        }}
      >
        <Table size="sm" fontFamily={'Inter'}>
          <Thead bg="#F9FAFB " h="45px">
            <Tr>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                CNPJ/CPF
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                E-mail
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                Vencimento
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                Valor
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                Pagamento
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                Status
              </Th>
              <Th
                textTransform={'capitalize'}
                fontFamily={'Inter'}
                fontSize={'13px'}
                color="#303030"
                fontWeight={'500'}
              >
                Boleto
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {listInfoBoleto.map((boleto) => {
              return (
                <Tr h="46px" key={boleto.boleto}>
                  <Td>{mask(boleto.cnpjCpf)}</Td>
                  <Td>{boleto.email}</Td>
                  <Td>{new Date(boleto.dtVencimento).toLocaleDateString()}</Td>
                  <Td>
                    {parseFloat(boleto.valor).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Td>
                  <Td>{getDateYearLesserThan1900(boleto.dtPagamento)}</Td>
                  <Td>
                    <Badge dtPagamento={boleto.dtPagamento} />
                  </Td>
                  <Td m={'auto'}>
                    {boleto.boleto === '' ||
                    new Date(boleto.dtPagamento).getFullYear() !== 1900 ? (
                      <Tooltip label="Não há boleto disponivel ainda ou ja foi pago">
                        <Image src={Download_Boleto} cursor={'not-allowed'} />
                      </Tooltip>
                    ) : (
                      <Image
                        src={Download_Boleto}
                        onClick={() => handleDownlaodBoleto(boleto.boleto)}
                        cursor={'pointer'}
                      />
                    )}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}
