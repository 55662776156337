import { Box, Fade, Flex, Grid, GridItem, Image, Link, Text } from '@chakra-ui/react'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSession } from 'src/contexts'
import Banner from '../../assets/home/static-home.png'
import { CardSponser } from './components/card-sponser'

export interface SponserInterface {
  aplicativo: string
  imagem: string
  tituloImagem: string
  descricao: string
  textoBotao: string
  linkBotao: string
}

export const Initial = () => {
  const { session } = useSession()
  const [sponser, setSponser] = useState<SponserInterface[]>()

  const getSettingsHomeSponsers = useCallback(async () => {
    const URL_ASSETS = process.env.REACT_APP_ASSETS_URL

    if(URL_ASSETS){
      await axios
        .get(`${URL_ASSETS}workspace/initial/config-home/configuracoes-home.json`)
        .then((response) => {
          const originAccount =
            session.origin !== undefined ? session.origin.toLowerCase() : ''
          const data = response.data

          const sponserFilter = data.filter((item: any) => {
            if (item.aplicativo.toLowerCase() === originAccount) {
              return item
            } else {
              return item.aplicativo.toLowerCase() === 'outros'
            }
          })

          setSponser(sponserFilter.slice(0, 3))
        })
        .catch((e) => console.error(e))
    }else{
      return null
    }
  }, [])

  
  useEffect(() => {
    getSettingsHomeSponsers()
  }, [getSettingsHomeSponsers])

  return (
    <Fade in={true} style={{ width: '100%' }} delay={0.2}>
      <Helmet>
        <title>Início | Workspace</title>
      </Helmet>
      <Box
        height={'100%'}
        overflowY={sponser ? 'auto' : 'hidden'}
        pb={{ sm: '120px', xl: '60px' }}
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
        }}
      >
        <Grid
          templateColumns={{ base: '1fr 1fr', xl: '3fr 2fr' }}
          gap={4}
          borderRadius="4px"
          overflowY={{ md: 'auto', '2xl': 'hidden' }}
          overflowX={'hidden'}
          css={{
            '&::-webkit-scrollbar': {
              background: '#ddd',
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A1A1AA',
              borderRadius: '24px',
            },
          }}
          backgroundImage={Banner}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundSize={'cover'}
          width={'100%'}
          height={{ '2xl': '460px', lg: '400px' }}
          border="1px solid #EAECF0"
          boxShadow={'0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}
        >
          <GridItem
            colSpan={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            paddingLeft={{ sm: '60px', '2xl': '0px' }}
          >
            <Flex flexDir="column">
              <Text fontFamily="Sora" fontSize="38px" fontWeight={'700'}>
                Boas-vindas ao novo
              </Text>
              <Text fontFamily="Sora" fontSize="38px" fontWeight={'700'}>
                <span style={{ color: '#006AFF' }}>Workspace</span> Londrisoft
              </Text>

              <Flex
                gap={'10px'}
                lineHeight={'24px'}
                flexDir="column"
                color="#000"
                fontFamily={'inter'}
                fontSize={'17px'}
                justifyContent={'space-between'}
              >
                <Text mt="20px">Olá, {session.name}!</Text>
                <Text mt="20px">
                  Fique por dentro das novidades e solucione as suas dúvidas na
                  nossa{' '}
                  <Link
                    href="https://londrisoft.movidesk.com/kb/pt-br"
                    isExternal
                    color="#006AFF"
                    fontWeight={'700'}
                    textDecoration={'underline'}
                  >
                    Base de Conhecimento.
                  </Link>
                </Text>
                <Text mt="5px">
                  Tem uma sugestão e quer compartilhar com a gente? Comente em
                  nosso{' '}
                  <Link
                    href="https://feedback.userreport.com/ccb1f13f-c50f-425e-a386-37a81026a972"
                    isExternal
                    color="#006AFF"
                    fontWeight={'700'}
                    textDecoration={'underline'}
                  >
                    mural de sugestões.
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minW={'400px'}
          >
            <Image
              src={
                'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/hws.png'
              }
              h={{ md: '310px', '2xl': '356px' }}
              alt="workspace_img"
            />
          </GridItem>
        </Grid>
        <Flex
          mt="10px"
          minH="calc(100vh - 480px)"
          justifyContent={'space-between'}
          gap={'12px'}
          id="sponsors"
        >
          {sponser && sponser?.map((item: SponserInterface, key: number) => (
              <CardSponser
                aplicativo={item.aplicativo}
                descricao={item.descricao}
                imagem={item.imagem}
                linkBotao={item.linkBotao}
                textoBotao={item.textoBotao}
                tituloImagem={item.tituloImagem}
                key={key}
              />
            ))}
        </Flex>
      </Box>
    </Fade>
  )
}