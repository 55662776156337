import { Flex, ListItem, Text, Tooltip, UnorderedList } from '@chakra-ui/react';
import { dataOrcamentoInterface } from './interface';
import { currency } from 'src/utilities/currency';
import { CircleHelp } from 'lucide-react';
import { useEffect, useState } from 'react';

type faturamentoInterfaceTotalOrder = Pick<
  dataOrcamentoInterface,
  'totalOrder'
>;

interface faturamentoInterface extends faturamentoInterfaceTotalOrder {
  obsPriceOrderTotal: string | null;
}

export const Faturamento = ({
  totalOrder,
  obsPriceOrderTotal,
}: faturamentoInterface) => {
  const [listArrayObs, setListArrayObs] = useState<string[]>([]);

  const handleObsPriceOrder = () => {
    if (obsPriceOrderTotal) {
      const listArrayObs = obsPriceOrderTotal.split('\n');

      setListArrayObs(listArrayObs.filter((i) => i !== ''));
      return;
    }
    setListArrayObs([]);
  };

  useEffect(() => {
    handleObsPriceOrder();
  }, [obsPriceOrderTotal]); //eslint-disable-line

  return (
    <Flex
      maxW={{ xl: '1024px', sm: 'full' }}
      w="full"
      mx="auto"
      p={{
        '2xl': '0 64px 10px 64px;',
        sm: '0 24px 10px 24px',
        base: '0 10px 10px 10px',
      }}
      mt="20px"
      mb="20px"
      borderBottom="1px solid #ccc"
    >
      <Flex flexDir="column" w="full">
        <Text
          fontFamily={'inter'}
          fontWeight={'700'}
          fontSize={'20px'}
          color="#606060"
        >
          Faturamento
        </Text>
        <Flex
          w="full"
          fontFamily={'inter'}
          mt="10px"
          gap="35px"
          flexDir={{ xl: 'row', md: 'row', sm: 'column', base: 'column' }}
        >
          <Flex
            flexDir={'column'}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={'1px solid rgba(96,96,96,.7)'}
          >
            <Text fontSize={'13px'} color="#6747b3">
              Subtotal
            </Text>
            <Text color="#606060">{currency(totalOrder?.subTotal)}</Text>
          </Flex>

          <Flex
            flexDir={'column'}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={'1px solid rgba(96,96,96,.7)'}
          >
            <Text fontSize={'13px'} color="#6747b3">
              Total Desconto
            </Text>
            <Text color="#606060">{currency(totalOrder?.totalDesconto)}</Text>
          </Flex>

          <Flex
            flexDir={'column'}
            h="50px"
            w="full"
            bg="#f8f6fe"
            borderBottom={'1px solid rgba(96,96,96,.7)'}
          >
            <Text fontSize={'13px'} color="#6747b3">
              Valor Total
            </Text>
            <Flex alignItems="center" gap="10px">
              <Text color="#606060">{currency(totalOrder?.total)}</Text>
              {listArrayObs.length > 0 && (
                <Tooltip
                  hasArrow
                  label={
                    <UnorderedList>
                      {listArrayObs.map((item) => (
                        <ListItem
                          key={`listObsPriceOrderTotal-${item}`}
                          fontFamily="inter"
                        >
                          {item}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  }
                >
                  <CircleHelp color="#606060" size={20} />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
