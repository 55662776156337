import { Embed, Page, Report, models, service } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'

import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import { ArrowsOutSimple } from 'phosphor-react'
import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  getAccessTokenBI,
  getCompanyReportData,
  postGravarLog,
} from 'src/services/api'
import './styles.css'

import { postGravarLogRequest } from '@/services/interfaces/postGravarLogRequest'
import { useSession } from 'src/contexts'
import { decryptedSimple, encryptedSimple } from 'src/utilities/hashing'

interface AnalyticsBICookiesProps {
  id: string
  empresaId: number
  type: string
  embedUrl: string
  tokenType: number
  accessToken: string
}

const idSerlipm = '9a442c19-0d8a-44cb-a108-56a351f7b812'

export const AnalyticsPRO = () => {
  const [report, setReport] = useState<Report>()
  const { session } = useSession()

  const [cookies, setCookie, removeCookie] = useCookies(['_abi'])
  const toast = useToast()

  const [sampleReportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: 'report',
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    })

  const sendLog = useCallback(async () => {
    const log: postGravarLogRequest = {
      EmpresaCnpj: session.company.document,
      IdUsuario: session.id,
      NomeUsuario: session.name,
      Aplicativo: 'AnalyticsPRO',
      Acao: 'Acesso ao AnalyticsPRO',
      TipoAcao: 'Login',
      EndPoint: '',
    }
    await postGravarLog(log)
  }, [])

  async function getActivePage(
    powerbiReport: Report,
  ): Promise<Page | undefined> {
    const pages = await powerbiReport.getPages()

    // Get the active page
    const activePage = pages.filter(function (page) {
      return page.isActive
    })[0]

    return activePage
  }

  const [displayMessage, setMessage] = useState(
    `The report is bootstrapped. Click the Embed Report button to set the access token`,
  )

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded')
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered')

        // Update display message
        setMessage('The report is rendered')
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail)
        }
      },
    ],
  ])

  const mockSignIn = async () => {
    if (session.plan.empresaId) {
      console.log('Signing in...')
      try {
        const companyReportData = await getCompanyReportData(
          session.company.document,
        )
        
        const reportEmbed = await getAccessTokenBI(
          session.plan.empresaId,
          companyReportData.company.powerBiGroupId,
          companyReportData.company.powerBiReportId,
          session.email,
        )

        if (reportEmbed?.message === 'OK') {
          setReportConfig({
            ...sampleReportConfig,
            type: reportEmbed.powerbi.type.toLocaleLowerCase(),
            id: reportEmbed.powerbi.embedReport[0].reportId,
            embedUrl: reportEmbed.powerbi.embedReport[0].embedUrl,
            accessToken: reportEmbed.powerbi.embedToken.token,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: false,
                },
              },
            },
          })

          const cookiesBI = encryptedSimple<AnalyticsBICookiesProps>({
            id: reportEmbed.powerbi.embedReport[0].reportId,
            empresaId: session.plan.empresaId,
            type: reportEmbed.powerbi.type,
            embedUrl: reportEmbed.powerbi.embedReport[0].embedUrl,
            accessToken: reportEmbed.powerbi.embedToken.token,
            tokenType: models.TokenType.Embed,
          })

          setCookie('_abi', JSON.stringify(cookiesBI), {
            path: '/',
            expires: new Date(reportEmbed.powerbi.embedToken.expiration),
          })
        } else {
          showError()
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      showError()
    }
  }

  function showError() {
    toast({
      title: 'Um erro inesperado ocorreu!',
      description:
        'Tente novamente em instantes, caso o erro persista entre em contato com o departamento de atendimento!',
      duration: 3000,
      position: 'top-right',
      status: 'error',
      isClosable: true,
    })
  }


  useEffect(() => {
    const cookiesBI: AnalyticsBICookiesProps | undefined = cookies._abi
      ? decryptedSimple<AnalyticsBICookiesProps>(cookies._abi)
      : undefined

    if (cookiesBI && cookiesBI.empresaId === session.plan.empresaId) {
      setReportConfig({
        ...sampleReportConfig,
        type: cookiesBI.type.toLocaleLowerCase(),
        id: cookiesBI.id,
        embedUrl: cookiesBI.embedUrl,
        accessToken: cookiesBI.accessToken,
        tokenType: cookiesBI.tokenType,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      })
    } else {
      mockSignIn()
    }
  }, [cookies])

  useEffect(() => {
    console.log(sampleReportConfig?.id)
  }, [sampleReportConfig])

  const changeSettings = () => {
    // Update the state "sampleReportConfig" and re-render DemoApp component
    setReportConfig({
      ...sampleReportConfig,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
      },
    })
  }

  // Delete the first visual using powerbi-report-authoring library
  const deleteVisual = async () => {
    if (!report) {
      console.log('Report not available')
      return
    }

    const activePage = await getActivePage(report as Report)

    if (!activePage) {
      console.log('No active page')
      return
    }

    // Get all visuals in the active page
    const visuals = await activePage.getVisuals()

    if (visuals.length === 0) {
      console.log('No visual left')
      return
    }

    // Get first visible visual
    const visual = visuals.find((v) => {
      return (
        v.layout.displayState?.mode ===
        models.VisualContainerDisplayMode.Visible
      )
    })

    // No visible visual found
    if (!visual) {
      console.log('No visible visual available to delete')
      return
    }

    try {
      // Documentation link: https://github.com/microsoft/powerbi-report-authoring/wiki/Visualization
      // Delete the visual
      await activePage.delete()

      console.log('Visual was deleted')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    sendLog()
  }, [sendLog])

  return (
    <Box id='biEmbed' width={'100%'} height={sampleReportConfig?.id === idSerlipm ? '90%' : '98%'} background={'#FFFFFF'} overflow={'auto'}>
      <Flex
        width={'inherit'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        paddingTop={'5px'}
        paddingEnd={'20px'}
      >
        <Button
          size={'1px'}
          variant={'unstyled'}
          display={'flex'}
          onClick={() => report?.fullscreen()}
          leftIcon={<ArrowsOutSimple size={20} color="#1155BB" />}
        >
          <Text fontSize={'12px'} color={'#1155BB'} marginTop={'3px'}>
            Full screen
          </Text>
        </Button>
      </Flex>
      <PowerBIEmbed
        cssClassName="panel_bi"
        embedConfig={sampleReportConfig}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={(embedObject: Embed) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received`,
          )
          setReport(embedObject as Report)
        }}
      />
    </Box>
  )
}
